
import { defineComponent } from "vue";
import BaseHeader from "@/components/BaseHeader2.vue";
import BaseTextarea from "@/components/BaseTextarea.vue";
import TextButton from "@/components/common/TextButton.vue";
import SelectBox from "@/components/common/SelectBox.vue";
import { useStore } from "vuex";

export default defineComponent({
  name: "UrgentUpload",
  components: {
    SelectBox,
    BaseHeader,
    TextButton,
    BaseTextarea,
  },
  data() {
    const store = useStore();
    return {
      store,
      page: 1,
      category: "",
      detail: "",
      categories: [
        { value: "배우", display: "배우" },
        { value: "스탭", display: "스탭" },
      ],
      isLoading: false,
    };
  },
  computed: {
    btnColor() {
      if (this.isValid) {
        return "var(--blue1-color)";
      }
      return "var(--grey1-color)";
    },
    isValid() {
      if (this.category && this.detail) {
        return true;
      }
      return false;
    },
  },
  methods: {
    submit() {
      if (!this.isValid) {
        return;
      }
      this.isLoading = true;
      this.$axios
        .post("/urgent", {
          category: this.category,
          detail: this.detail,
        })
        .then(() => {
          this.$pushGAEvent("urgent_write_complete", {
            announcement_category: this.category === "배우" ? "actor" : "staff",
          });
          this.isLoading = false;
          this.store.dispatch(
            "urgent/updateSelectedTabName",
            this.category === "배우" ? "actor" : "staff"
          );
          this.$router.push("/urgent");
        })
        .catch(() => {
          this.isLoading = false;
        });
    },
  },
  beforeMount() {
    this.$flutter.callHandler("hideGnb");
  },
  beforeUnmount() {
    this.$flutter.callHandler("showGnb");
  },
  mounted() {
    const category = this.store.getters["urgent/selectedTabName"];
    if (category) {
      this.category = category === "actor" ? "배우" : "스탭";
    }
    this.$pushGAEvent("urgent_write_try", {
      announcement_category: category,
    });
  },
});
